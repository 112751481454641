import React from "react"

import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Slider from "../components/Slider"
import BookingLine from "../components/BookingLine"
import RoomSlider from "../components/RoomSlider"
import Services from "../components/Services"
import Satisfaction from "../components/Satisfaction"
import Team from "../components/Team"
import Contact from "../components/Contact"
import InstagramBar from "../components/InstagramBar"
import Genres from "../components/Genres"
import Layout from "../components/Layout"
import AOS from 'aos';
import 'aos/dist/aos.css';

export class HomePageTemplate extends React.Component {

  componentDidMount() {
    AOS.init({
      once: true,
      easing: 'ease-in-out',
      useClassNames: true
    })
  }

  componentWillReceiveProps() {
    AOS.refreshHard();
  }

  render() {

    const {
      localeData, 
      headerSlides, 
      title, 
      rooms, 
      services, 
      satisfaction,
      team,
      testimonials,
      genres,
      instaFeed,
      keywords,
      description
    } = this.props

    return (
      <Layout localeData={localeData} title={title} isHome={true} keywords={keywords} description={description}>
        <Slider slides={headerSlides} localeData={localeData} />
        <BookingLine localeData={localeData} isHome={true}/>
        <RoomSlider rooms={rooms} />
        <Services services={services} />
        <Satisfaction satisfaction={satisfaction} testimonials={testimonials} />
        <Team team={team} />
        <Genres genres={genres} />
        <Contact />
        <InstagramBar data={instaFeed} animate={true} className="instagram_area container-fluid pt-90"/>
      </Layout>
    );
  }
}

HomePageTemplate.propTypes = {
  localeData: PropTypes.shape({
    locale: PropTypes.string,
    availableLocales: PropTypes.array,
    defaultLocale: PropTypes.string,
    originalPath: PropTypes.string,
    relativeSlugs: PropTypes.object
  }),
  headerSlides: PropTypes.array,
  title: PropTypes.string,
  rooms: PropTypes.array,
  services: PropTypes.array,
  satisfaction: PropTypes.shape({
    title: PropTypes.string,
    bgImage: PropTypes.string,
    html: PropTypes.string,
  }),
  team: PropTypes.string,
  testimonials: PropTypes.array,
  genres: PropTypes.array,
  instaFeed: PropTypes.array
}

const HomePage = ({ pageContext: { locale, localeData }, data }) => {
  const { frontmatter: page } = data.home
  const { edges: rooms } = data.rooms
  const { edges: services } = data.services
  const { satisfaction } = data
  const { team } = data
  const { edges: testimonials } = data.testimonials
  const { edges: genres } = data.genres

  return (
      <HomePageTemplate
        keywords={page.keywords}
        description={page.description}
        title={page.title}
        headerSlides={page.headerSlides}
        rooms={rooms}
        localeData={localeData}
        
        services={services}
        satisfaction={satisfaction}
        team={team}
        testimonials={testimonials}
        genres={genres}
      />
  )
}

HomePage.propTypes = {
  localeData: PropTypes.shape({
    locale: PropTypes.string,
    availableLocales: PropTypes.array,
    defaultLocale: PropTypes.string,
    originalPath: PropTypes.string,
    relativeSlugs: PropTypes.object
  }),
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default withI18next()(HomePage);

export const pageQuery = graphql`
query HomePageTemplate($id: String!, $locale: String!) {
  home: markdownRemark(id: {eq : $id}) {
      html
      frontmatter {
        title
        keywords
        description
        headerSlides {
          titleIntro
          title
          subTitle
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          videoId
          videoChannel
          customClass
          textShadow
          link
          buttonLabel
        }
      }
    }
  rooms: allMarkdownRemark(
    filter: {frontmatter: {templateKey: {eq: "room"}},fields: {locale: {eq: $locale}}}
    sort: {fields: frontmatter___order, order: ASC}
    ) {
    edges {
      node {
        frontmatter {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          price
          slug
        }
        excerpt(format: HTML)
      }
    }
  }
  events: allMarkdownRemark(
    filter: {frontmatter: {templateKey: {eq: "event"}},fields: {locale: {eq: $locale}}}
    sort: {fields: [frontmatter___date, frontmatter___title], order: [DESC]}
    limit: 3
    ) {
    edges {
      node {
        frontmatter {
          title
          slug
          date
          description
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
  news: allMarkdownRemark(
    filter: {frontmatter: {templateKey: {eq: "news"}},fields: {locale: {eq: $locale}}}
    sort: {fields: [frontmatter___date, frontmatter___title], order: [DESC]}
    limit: 3
    ) {
    edges {
      node {
        frontmatter {
          title
          slug
          date
          description
          image {
            childImageSharp {
              fluid(maxWidth: 300, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
  services: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/blocks/services/"} fields: {locale: {eq: $locale}}}
    sort: {fields: frontmatter___order, order: ASC}
    ) {
    edges {
      node {
        frontmatter {
          title
          icon {
            childImageSharp {
              fixed(width: 51, height: 47, quality: 60) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 762, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          link
        }
        html
      }
    }
  }
  satisfaction: markdownRemark(fileAbsolutePath: {regex: "/blocks/satisfaction/"} fields: {locale: {eq: $locale}}) {
    html
    frontmatter {
      title
      bgImage {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
  team: markdownRemark(fileAbsolutePath: {regex: "/blocks/team/"} fields: {locale: {eq: $locale}}) {
    html
    frontmatter {
      title
      imagechef {
        childImageSharp {
          fluid(maxWidth: 300, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagechef_alt
      imageowner {
        childImageSharp {
          fluid(maxWidth: 300, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageowner_alt
      imagesales {
        childImageSharp {
          fluid(maxWidth: 300, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagesales_alt
      imagesalesmanager {
        childImageSharp {
          fluid(maxWidth: 300, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagesalesmanager_alt
      imagerestaurantmanager {
        childImageSharp {
          fluid(maxWidth: 300, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagerestaurantmanager_alt
      imagehousekeepingmanager {
        childImageSharp {
          fluid(maxWidth: 300, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagehousekeepingmanager_alt
      imageeventorganizer {
        childImageSharp {
          fluid(maxWidth: 300, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageeventorganizer_alt
      imagehotelmanager {
        childImageSharp {
          fluid(maxWidth: 300, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagehotelmanager_alt
      imagefinancemanager {
        childImageSharp {
          fluid(maxWidth: 300, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagefinancemanager_alt
      imagefrontofficemanager {
        childImageSharp {
          fluid(maxWidth: 300, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagefrontofficemanager_alt
      imagetechmanager {
        childImageSharp {
          fluid(maxWidth: 300, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagetechmanager_alt
    }
  }
  testimonials: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/blocks/testimonials/"} fields: {locale: {eq: $locale}}}
    sort: {fields: frontmatter___date, order: DESC}
    ) {
    edges {
      node {
        frontmatter {
          name
        }
        html
      }
    }
  }
  genres: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/blocks/genres/"} fields: {locale: {eq: $locale}}}
    sort: {fields: [frontmatter___order], order: ASC}
    ) {
    edges {
      node {
        frontmatter {
          category
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 700, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
  } 
}`